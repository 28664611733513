import React from "react"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"

import { LoadingContext } from "./context/loadingContext"

const client = new ApolloClient({
  // uri: `http://localhost:4000/64464554ed469874884b87de/graphql`,
  uri: `${process.env.GATSBY_API_URL}64464554ed469874884b87de/graphql`,
  cache: new InMemoryCache(),
  credentials: "include",
})

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <LoadingContext>
        <>{element}</>
      </LoadingContext>
    </ApolloProvider>
  )
}
